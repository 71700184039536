export default class SiteHeader {

  headerWrapper: HTMLElement | null;
  navElement: HTMLElement | null;
  focusableElements: Array<HTMLElement>;

  //foldable main menu
  lastScrollTop: number;
  scrollHandler: EventListener;
  resizeHandler: EventListener;
  focusoutHandler: EventListener;

  //dropdowns
  clickHandler: EventListener;
  keyboardHandler: EventListener;

  constructor(id: string = 'site-header') {

    this.headerWrapper = document.getElementById(id);

    if(!this.headerWrapper) {
      throw new Error("Header element not found");
    }

    this.navElement = this.headerWrapper.querySelector('nav');

    if(!this.navElement) {
      throw new Error("Nav element not found");
    }

    this.focusableElements = new Array();
    this.headerWrapper?.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])').forEach((element) => {
      if(element instanceof HTMLElement) {
        this.focusableElements.push(element);
      }
    });
    
    this.clickHandler = this.handleClickEvent.bind(this);
    this.keyboardHandler = this.handleKeyboardEvent.bind(this);
    this.scrollHandler = this.handleScrollEvent.bind(this);
    this.resizeHandler = this.handleResizeEvent.bind(this);
    this.focusoutHandler = this.handleFocusoutEvent.bind(this);

		this.enable();
	}

  enable() {
    window.addEventListener('click', this.clickHandler, false);
    window.addEventListener('scroll', this.scrollHandler, false);
    window.addEventListener('resize', this.resizeHandler, false);
    this.navElement?.addEventListener('focusout', this.focusoutHandler, true);
    this.toggleMenuIfScrolledDown();
  }

  destroy() {
    window.removeEventListener('click', this.clickHandler);
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
    this.navElement?.removeEventListener('focusout', this.focusoutHandler);
  }

  handleScrollEvent(e: Event) {
    this.toggleMenuIfScrolledDown();
  }

  toggleMenuIfScrolledDown() {
    let currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    document.body.classList.toggle('scrolled-down', currentScrollTop > 50);
  }

  handleResizeEvent(e: Event) {
    //document.body.classList.remove('scrolled-down');
    this.closeMenu();
  }

  handleClickEvent(e: MouseEvent) {
  
    const target = e.target as HTMLElement;
    switch(target?.id) {
      case 'button-toggle-menu':
        this.toggleMenu();
        break;
    }

    if(target == document.body || target.classList.contains('menu-link')) {
      this.closeMenu();
    }
  };

  handleKeyboardEvent(e: KeyboardEvent) {

    switch (e.key) {

      case "Escape":
        this.closeMenu();
        break;

      case "Tab":

        // console.log('site-header tabbing', e.target);
        
        // let first = this.focusableElements[0];
        // let last = this.focusableElements[this.focusableElements.length - 1];
        // let shift = e.shiftKey;
        // if (shift) {
        //     if (e.target === first) { // shift-tab pressed on first input in dialog
        //         last.focus();
        //         e.preventDefault();
        //     }
        // } else {
        //     if (e.target === last || e.target == this.navElement ) { // tab pressed on last input in dialog
        //         first.focus();
        //         e.preventDefault();
        //     }
        // }
        break;
    }
  }

  handleFocusoutEvent(e: FocusEvent) {
    //console.log(e);
    //this.closeMenu();
  }

  openMenu() {
    document.body.classList.add('menu-open');
    window.addEventListener('keydown', this.keyboardHandler, false);
    this.navElement?.focus();
  }

  closeMenu() {
    document.body.classList.remove('menu-open');
    window.removeEventListener('keydown', this.keyboardHandler);
  }

  toggleMenu() {

    if(document.body.classList.contains('menu-open')) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }
}