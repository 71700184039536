import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from "@uppy/webcam";
import Czech from '@uppy/locales/lib/cs_CZ'
import Informer from '@uppy/informer';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

declare var plausible: any;

export default class OrderForm {

  form: HTMLFormElement;
  mainElement: HTMLElement | null;
  formModal: HTMLElement | null;
  inputs: NodeListOf<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  focusableElements: Array<HTMLElement>;
  fileUpload: HTMLInputElement;
  orderButtons: NodeListOf<HTMLButtonElement>;
  csrf: string;
  submitButton: HTMLButtonElement | null;
  uppy: Uppy;

  //dropdowns
  clickHandler: EventListener;
  keyboardHandler: EventListener;
  changeHandler: EventListener;
  inputHandler: EventListener;
  submitHandler: EventListener;

  constructor(id: string = 'order-form') {

    const form = document.getElementById(id);
    this.mainElement = document.getElementById('main');

    if(form instanceof HTMLFormElement) {
      this.form = form;
    } else {
      throw new Error("Element with id '" + id + "' is not a form element");
    }

    this.formModal = this.form.parentElement;

    this.inputs = this.form.querySelectorAll('input, select, textarea');

    const orderLinks = document.body.querySelectorAll('a.button-order').forEach((element) => {
      if(element instanceof HTMLAnchorElement) {
        this.switchLinkToButton(element);
      }
    });

    this.focusableElements = new Array();
    this.formModal?.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])').forEach((element) => {
      if(element instanceof HTMLElement) {
        this.focusableElements.push(element);
      }
    });

    const submitButton = form.querySelector('#order-form-submit');

    if(submitButton instanceof HTMLButtonElement) {
      this.submitButton = submitButton;
    }
    
    this.clickHandler = this.handleClickEvent.bind(this);
    this.keyboardHandler = this.handleKeyboardEvent.bind(this);
    this.changeHandler = this.handleChangeEvent.bind(this);
    this.inputHandler = this.handleInputEvent.bind(this);
    this.submitHandler = this.handleSubmitEvent.bind(this);

    const fileUpload = form.querySelector('#uppy');
    if(fileUpload) {
      
      this.uppy = new Uppy({
          locale: Czech,
          restrictions: {
            maxFileSize: 10 * 1024 * 1024,
            maxNumberOfFiles: 1,
            allowedFileTypes: [
              'image/jpeg',
              'image/gif',
              'image/png',
              'image/bmp',
              'image/tiff',
              'application/pdf'
            ]
          },
        })
        .use(Dashboard, {
          target: fileUpload,
          inline: true,
          proudlyDisplayPoweredByUppy: false,
          width: '100%',
          height: 'auto',
          fileManagerSelectionType: 'files',
          hideCancelButton: true,
          hideUploadButton: true,
          hideProgressAfterFinish: true,
          note: 'Maximálně 1 soubor o velikosti 10 MB',
          disableInformer: true,
          locale: {
            strings: {
              dropPasteImportFiles: '',
              //dropPasteImportFiles: 'Foto žádanky na fyzioterapii od praktického lékaře nebo specialisty ',
              myDevice: 'Nahrát',
              importFrom: 'Vyfotit žádanku'
            }
          }
        })
        .use(Webcam, {
          target: Dashboard,
          modes: ['picture'],
          locale: {
            strings: {
              pluginNameCamera: 'Vyfotit',
            }
          }
        })
        .use(Informer, { target: '#informer' });

      this.form.querySelector('input[type="file"]')?.remove();
      //this.form.querySelector('label[for="file"]')?.setAttribute('style', 'display:none;');
    }
    
		this.enable();
	}

  enable() {

    this.inputs.forEach(this.checkEmptiness);

    document.addEventListener('click', this.clickHandler, false);
    
    this.form.addEventListener('change', this.changeHandler, false);
    this.form.addEventListener('input', this.inputHandler, false);
    this.form.addEventListener('submit', this.submitHandler, false);
  }

  destroy() {
    document.removeEventListener('click', this.clickHandler);
    
    this.form.removeEventListener('change', this.changeHandler);
    this.form.removeEventListener('input', this.inputHandler);
    this.form.removeEventListener('submit', this.submitHandler);
  }

  handleClickEvent(e: MouseEvent) {

    const target = e.target as HTMLElement;
    switch(target?.id) {
      case "order-form-close":
      case "order-form-close-2":
      case "order-form-modal":
        this.closeForm(false);
        break;

      case "order-form-close-and-reset":
        this.closeForm(true);
        break;
    }

    if(target.classList.contains('button-order')) {
      this.openForm();
    }
  };

  handleKeyboardEvent(e: KeyboardEvent) {

    switch (e.key) {

      case "Escape":
        this.closeForm();
        break;

      case "Tab":
        
        let first = this.focusableElements[0];
        let last = this.focusableElements[this.focusableElements.length - 1];
        let shift = e.shiftKey;
        if (shift) {
            if (e.target === first) { // shift-tab pressed on first input in dialog
                last.focus();
                e.preventDefault();
            }
        } else {
            if (e.target === last || e.target == this.form ) { // tab pressed on last input in dialog
                first.focus();
                e.preventDefault();
            }
        }
        break;
    }
  }

  handleChangeEvent(e: Event) {
    if(
      (
        e.target instanceof HTMLInputElement
        && e.target?.name != 'files[]'
      )
      || e.target instanceof HTMLTextAreaElement
      || e.target instanceof HTMLSelectElement
    )
    {
      this.checkValidity(e.target);
    }
  }

  handleInputEvent(e: Event) {
    if(
      e.target instanceof HTMLTextAreaElement
    )
    {
      this.resizeTextArea(e.target);
    }
  }

  resizeTextArea(textarea: HTMLTextAreaElement) {
    const fontSize = 16; // with lineheight
    const padding = 1.25 * fontSize + 0.35 * fontSize;
    const minLines = 6;
    const lineHeight = fontSize * 1.3;
    const minHeight = minLines * lineHeight + padding;
    textarea.style.height = minHeight + 'px';
    textarea.style.height = Math.ceil(Math.max(minHeight, textarea.scrollHeight) / lineHeight) * lineHeight - fontSize + "px";
  }

  handleSubmitEvent(e: SubmitEvent) {
    
    e.preventDefault();
    this.submitForm();
  }

  private async submitForm() {

    this.setLoading(true);

    const formData = new FormData(this.form);
    this.disableForm(true); //disable after getting data!!!

    await this.getCsrf();

    const files = this.uppy.getFiles();
    if(files[0]) {
      formData.append('file', files[0].data);
    }

    if(plausible) {
      plausible('Objednávka', {
        props: {
          path: document.location.pathname
        }
      });
    }

    grecaptcha.ready(() => {
      grecaptcha
        .execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, {action: 'objednavka'})
        .then((token) => {

          formData.append('token', token);

          fetch('/objednavka', {
            method: "POST",
            body: formData
          }).then(response => {

            this.setLoading(false);

            if(!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json()

          }).then(data => {

            if(data.alerts) {

              for(const fieldName in data.alerts) {

                const message = data.alerts[fieldName];

                if(fieldName == 'file') {

                  //handle uppy error
                  this.uppy.info(message, 'error');
                  //console.error(fieldName, message);

                } else {

                  const fieldWrapper = this.form.querySelector('.field-' + fieldName);
                  const field = fieldWrapper?.querySelector('input');
                  const prevErrorMessageWrapper = fieldWrapper?.querySelector('.error-message');

                  if(prevErrorMessageWrapper) {
                    prevErrorMessageWrapper.remove();
                  }

                  const errorMessageWrapper = document.createElement('div');
                  errorMessageWrapper.classList.add('error-message');

                  const errorMessage = document.createElement('p');
                  errorMessage.textContent = message;

                  errorMessageWrapper.append(errorMessage)

                  if(fieldWrapper && field) {
                    field.classList.add('invalid');
                    fieldWrapper.append(errorMessageWrapper);
                  }
                }
              }

              this.disableForm(false);

            } else {
              this.showSuccess();
            }

          }).catch(error => {

            this.setLoading(false);
            this.showError();

          });
        })
    });
  }

  disableForm(disabled: boolean) {
    this.focusableElements.forEach((element) => {
      this.setElementAsDisabled(element, disabled);
    });

    this.uppy.getPlugin('Dashboard')?.setOptions({disabled: disabled});
  }

  setLoading(loadingActive: boolean = false) {

    if(loadingActive) {

      document.body.classList.add('is-loading');

    } else {

      document.body.classList.add('loading-fade-out');

      setTimeout(() => {
        document.body.classList.remove('is-loading');
        document.body.classList.remove('loading-fade-out');
      }, 500);
    }
  }

  showSuccess() {

    let responseElement = this.getFormResponseElement('success','Děkujeme');

    this.form.hidden = true;
    this.form.parentElement?.insertBefore(responseElement, this.form);

  }

  showError(message: string = 'Stala se neočekávaná chyba. Kontaktujte nás prosím na <a href="mailto:rezervace@vitavita.cz">rezervace@vitavita.cz</a>') {

    let responseElement = this.getFormResponseElement('error', 'Omlouváme se', message);

    this.form.hidden = true;
    this.form.parentElement?.insertBefore(responseElement, this.form);
  }

  private getFormResponseElement(type:string, headline: string, message: string = ""): HTMLElement {

    let image = document.createElement('img');
    image.role = "presentation";
    image.src = '/assets/'+type+'.svg';

    let responseElement = document.createElement("div");
    responseElement.id = "order-form-" + type;

    let headlineElement = document.createElement("h1");
    headlineElement.classList.add('h1');
    headlineElement.textContent = headline;

    let messageElement = document.createElement("p");
    messageElement.innerHTML = message;

    let closeButton = document.createElement('button');
    closeButton.id = 'order-form-close-and-reset';
    closeButton.classList.add('button');
    closeButton.classList.add('button-primary');
    closeButton.textContent = 'Zavřít';
    
    responseElement.append(image);
    responseElement.append(headlineElement);
    responseElement.append(messageElement);
    responseElement.append(closeButton);

    return responseElement;
  }

  setElementAsDisabled(element: HTMLElement, disabled: boolean) {

    if(
      element instanceof HTMLInputElement
      || element instanceof HTMLSelectElement
      || element instanceof HTMLTextAreaElement
      || element instanceof HTMLButtonElement
    ) {
      element.disabled = disabled;
    }

  }

  checkValidity(element: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null): Boolean {
    
    let isValid = false;

    if(element) {
      isValid = element.checkValidity()

      element.classList.toggle('invalid', !isValid);
      element.classList.toggle('valid', isValid);

      if(isValid) {
        const errorMessage = this.form.querySelector('.field-' + element.name +' .error-message');
        if(errorMessage instanceof HTMLElement) {
          errorMessage.classList.add('scale-out-ver-top');
          setTimeout(() => {
            errorMessage.remove();
          }, 500);
          
        }
      }
  
      this.checkEmptiness(element);
    }

    return isValid;
  }

  checkEmptiness(element: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement): Boolean {

    let isEmpty = element.value == "";

    element.classList.toggle('empty', isEmpty);
    element.classList.toggle('not-empty', !isEmpty);

    return isEmpty;
  }

  openForm() {
    document.body.classList.add('order-form-open');
    this.mainElement?.setAttribute('aria-hidden', 'true');
    document.addEventListener('keydown', this.keyboardHandler, false);
    this.formModal!.scrollTo(0,0);// = false;
    this.form.querySelectorAll('textarea').forEach(this.resizeTextArea);
    this.form.focus();
    this.getCsrf();
  }

  closeForm(reset: boolean = false) {

    this.form.classList.add('slide-out-bottom');
    this.formModal?.classList.add('fade-out');

    setTimeout(() => {
      document.body.classList.remove('order-form-open');
      this.form.classList.remove('slide-out-bottom');
      this.formModal?.classList.remove('fade-out');
      document.removeEventListener('keydown', this.keyboardHandler);
      this.mainElement?.setAttribute('aria-hidden', 'false');

      if(reset) this.resetForm();
    }, 500);

  }

  resetForm() {

    this.inputs.forEach((element) => {
      this.resetInput(element);
    });

    this.formModal?.querySelectorAll('#order-form-success, #order-form-error')?.forEach((element) => {
      element.remove();
    });

    this.uppy.getFiles().forEach((file) => {
      this.uppy.removeFile(file.id);
    })

    this.disableForm(false);
    this.form.hidden = false;
  }

  resetInput(element: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) {

    if(element instanceof HTMLInputElement && element.type == 'checkbox') {
      element.checked = false;
    } else {
      element.value = "";
    }
    
    this.checkEmptiness(element);
  }

  private async getCsrf() {

    if(!this.csrf) {

      const csrfResponse = await fetch('/csrf', {
        method: 'POST'
      });

      const csrfResult = await csrfResponse.json();

      this.csrf = csrfResult.csrf;

      const csrfInput = document.createElement("input");
      csrfInput.hidden = true;
      csrfInput.name = "csrf";
      csrfInput.value = this.csrf + "";

      this.form.append(csrfInput);
      //console.log(this.csrf);
      this.uppy.setMeta({
        csrf: this.csrf
      });
      // this.uppy = this.uppy.setState({
      //   meta:{
      //     csrf: this.csrf
      //   }
      // });
      //console.log(this.uppy.opts.meta.csrf);
    }
  }

  switchLinkToButton(link: HTMLAnchorElement) {

    const button = document.createElement("button");

    button.classList.add(...Array.from(link.classList));
    button.id = link.id;
    button.innerHTML = link.innerHTML;

    link.replaceWith(button);

  }

  toggleFocusableElements(disabled: boolean) {

    this.focusableElements.forEach((element) => {
      element.classList.toggle('disabled', disabled);
      element.setAttribute('tabindex', disabled ? '-1' : '0');
    })
  }

  prefill() {

    const inputs = [
      {
        id: 'name',
        value: 'T'
      },
      {
        id: 'email',
        value: 'test@test.cz'
      },
      {
        id: 'phone',
        value: '123456678'
      }
    ]

    inputs.forEach(input => {
      this.prefillElement(input.id, input.value);
    });

    const legal = document.getElementById('agree') as HTMLInputElement;
    if(legal) legal.checked = true;

    this.inputs.forEach(this.checkEmptiness);
  }

  prefillElement(id: string, value: string) {
    const input = document.getElementById(id) as HTMLInputElement;
    if(input) input.value = value;
  }

}