export default class PersonListItem {

  isOpen: boolean = false;
  height: number;

  wrapper: HTMLElement;
  fold: HTMLElement;
  button: HTMLButtonElement;
  image: HTMLImageElement;

  clickHandler: EventListener;
  loadHandler: EventListener;

  constructor(wrapper: HTMLElement) {

    this.wrapper = wrapper;
    
    const fold = this.wrapper.querySelector('.person-item-fold');
    if(fold instanceof HTMLElement) {
      this.fold = fold;
    } else {
      throw new Error("Fold element not found");
    }

    const button = this.wrapper.querySelector('button');

    if(button instanceof HTMLButtonElement) {
      this.button = button;
    } else {
      throw new Error("Button element not found");
    }

    const image = this.wrapper.querySelector('img');
    if(image instanceof HTMLImageElement) {
      this.image = image;
    }

    this.clickHandler = this.handleClickEvent.bind(this);
    this.loadHandler = this.handleLoadEvent.bind(this);

    this.updateScrollHeightVar();

    this.enable();
  }

  enable() {
    this.button.addEventListener('click', this.clickHandler, false);
    this.button.addEventListener('load', this.loadHandler, false);
  }

  destroy() {
    this.button.removeEventListener('click', this.clickHandler);
  }

  handleClickEvent(e: MouseEvent) {
    this.toggle();
  };

  handleLoadEvent(e: Event) {
    this.updateScrollHeightVar();
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }

  toggle(newState: boolean = !this.isOpen) {
    this.updateScrollHeightVar();
    this.fold.classList.toggle('unfolded', newState);
    this.button.setAttribute('aria-expanded', String(newState));
    this.button.textContent = newState ? 'Číst méně' : 'Číst více';
    this.isOpen = newState;
  }

  updateScrollHeightVar() {

    let newHeight = 0;

    for (let i = 0; i < this.fold.children.length; i++) {

      const element = this.fold.children[i] as HTMLElement;
      let totalHeight =
        element.offsetHeight
        + parseInt(window.getComputedStyle(element).getPropertyValue('margin-top'))
        + parseInt(window.getComputedStyle(element).getPropertyValue('margin-bottom'));

      newHeight += totalHeight;
    }

    this.height = newHeight;

    this.fold.setAttribute(
      'style',
      '--full-height: ' + this.height + 'px;');

    return this.height;
  }
}