import 'normalize.css';
import './styles/index.scss';

import SiteHeader from './modules/site-header';
import OrderForm from './modules/order-form';
import PersonList from './modules/person-list';

let siteHeader: SiteHeader;
let personList : PersonList;
let orderForm: OrderForm;

function init() {
  
  if(document.getElementById('site-header'))
  {
    siteHeader = new SiteHeader('site-header');
  }


  if(document.getElementById('kdo-jsme'))
  {
    personList = new PersonList('kdo-jsme');
  }

  if(document.getElementById('order-form')) {
    orderForm = new OrderForm('order-form');

    //orderForm.prefill();
    //orderForm.openForm();
  }

  // if(Sentry) {
  //   Sentry.init({
  //     dsn: import.meta.env.VITE_SENTRY_DSN,
  //     environment: import.meta.env.VITE_SENTRY_ENV,
  //     tunnel: '/sentry',
  //     sampleRate: 1,
  //     tracesSampleRate: 0,
  //     sendClientReports: false,
  //     autoSessionTracking: false
  //   });
  // }
}

function unload() {

  if(siteHeader) {    
    siteHeader.destroy();
  }
}

// run once when page loads
if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}
