import PersonListItem from "./person-list-item";

export default class PersonList {

  isOpen: boolean = false;
  height: number;

  wrapper: HTMLElement;
  fold: HTMLElement;
  button: HTMLButtonElement;
  
  items: Array<PersonListItem> = new Array();

  images: Array<HTMLImageElement> = new Array();

  //to reset maybe
  resizeHandler: EventListener;
  
  //foldout
  clickHandler: EventListener;

  loadHandler: EventListener;


  constructor(id: string) {

    //wrapper
    const wrapper = document.getElementById(id);

    if(wrapper) {
      this.wrapper = wrapper;
    } else {
      throw new Error("Header element not found");
    }

    const fold = wrapper.querySelector('.person-list-fold');
    
    if(fold instanceof HTMLElement) {
      this.fold = fold;
    } else {
      throw new Error("Fold element not found");
    }
    
    //items
    this.wrapper.querySelectorAll('.person-item').forEach(element => {
      
      if(element instanceof HTMLElement) {
        this.items.push(new PersonListItem(element));
      }
    });

    //buttons
    const button = this.wrapper.querySelector('#button-fold-all');

    if(button instanceof HTMLButtonElement) {
      this.button = button;
    } else if (!button) {
      throw new Error("button all element not found");
    } else {
      throw new Error("button all is wrong element");
    }

    const images = this.wrapper.querySelectorAll('img');

    images.forEach(element => {
      if(element instanceof HTMLImageElement) {
        this.images.push(element);
      }
    });
    
    this.clickHandler = this.handleClickEvent.bind(this);
    this.resizeHandler = this.handleResizeEvent.bind(this);
    this.loadHandler = this.handleLoadEvent.bind(this);

		this.enable();
	}

  enable() {
    this.button.addEventListener('click', this.clickHandler, false);
    
    this.images.forEach(element => {
      element.addEventListener('load', this.loadHandler, true);
    });

    window.addEventListener('resize', this.resizeHandler, false);

    this.updateScrollHeightVar();
  }

  destroy() {
    this.wrapper.removeEventListener('click', this.clickHandler);
    window.removeEventListener('resize', this.resizeHandler);

    this.items.forEach((element) => element.destroy());
  }

  handleResizeEvent(e: Event) {
    this.updateScrollHeightVar();
    this.items.forEach((item) => item.updateScrollHeightVar());
  }

  handleClickEvent(e: MouseEvent) {
    this.toggle();
  };

  handleLoadEvent(e: Event) {
    console.log(e);
    this.updateScrollHeightVar();
  }

  toggle(newState: boolean = !this.isOpen) {
    this.updateScrollHeightVar();
    this.fold.classList.toggle('unfolded', newState);
    this.button.setAttribute('aria-expanded', String(newState));
    this.button.textContent = newState ? 'Číst méně' : 'Číst více';
    this.isOpen = newState;
  }

  updateScrollHeightVar() {

    let heights = new Array();

    for (let i = 0; i < this.fold.children.length; i++) {

      const element = this.fold.children[i] as HTMLElement;
      let totalHeight =
        element.offsetHeight
        + parseInt(window.getComputedStyle(element).getPropertyValue('margin-top'))
        + parseInt(window.getComputedStyle(element).getPropertyValue('margin-bottom'));

      heights.push(totalHeight);
    }

    this.height = Math.max(...heights);

    let newFoldedHeight = 0;
    newFoldedHeight += this.fold.querySelector('picture')?.scrollHeight ?? 0;
    newFoldedHeight += 16 * 1.3 * 10.5;

    this.fold.setAttribute(
      'style',
      '--full-height: ' + this.height + 'px;--folded-height:' + newFoldedHeight + 'px;');

    return this.height;
  }
}